import React from "react";
import {
  Box, Chip, Grid, Typography,
} from "@mui/material";
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n";
import parse from "html-react-parser";
import styled from "@emotion/styled";
import _ from "lodash";
import { transformArticleDate } from "../utils/screen";

function ArticleCard({ attributes, isBig = false, isDark = false }) {
  const { locale, config } = useLocalization();
  const pathImage = attributes.header_image?.data?.attributes?.formats?.small?.url
    || attributes.header_image?.data?.attributes?.url;
  const isDraft = !attributes.publishedAt;
  const categories = _.get(attributes, "article_categories.data", []);
  const hrefLang = config.find((conf) => conf.code === locale)?.hrefLang;
  const [isSafari, setIsSafari] = React.useState(true);

  React.useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);

  return (
    <LocalizedLink to={`/blog/${attributes.slug}`}>
      <Box component="article">
        <Box
          padding={2}
          sx={{
            height: isBig ? "500px" : "300px",
            display: "block",
            backgroundSize: "cover",
            ...(pathImage
              ? { backgroundImage: `url(${pathImage})` }
              : { background: (theme) => theme.palette.primary.main }),
            backgroundPosition: "center",
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        >
          <Grid container spacing={1}>
            {categories.map(({ attributes }) => (
              <Grid item xs>
                <Chip
                  sx={{ backgroundColor: "white" }}
                  label={attributes.name}
                  variant="filled"
                />
              </Grid>
            ))}
          </Grid>
          {isDraft && (
            <Chip
              sx={{
                color: "white",
                backgroundColor: (theme) => theme.palette.secondary.main,
                ml: !_.isEmpty(categories) && 1,
              }}
              label="Draft"
              variant="filled"
            />
          )}
        </Box>
        <Typography mt={2} color={isDark && "white"} fontWeight={700}>
          {attributes.title}
        </Typography>
        <StyledContent>
          <div className={`${isSafari ? "safari" : "default"}`}>
            <Typography color={isDark && "white"} variant="small">
              {parse(attributes.content)}
            </Typography>
          </div>
        </StyledContent>
        <Typography color="neutral.main" variant="small" fontStyle="italic">
          <i>{transformArticleDate(attributes.publish_date, hrefLang)}</i>
        </Typography>
      </Box>
    </LocalizedLink>
  );
}

const StyledContent = styled(Box)`
  .safari {
    max-height: 90px;
    overflow: hidden;
    margin: 12px 0;
    > span {
      display: -webkit-box;
    }
  }
  .default {
    > span {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      margin: 12px 0;
    }
  }
  * ul {
    padding: 0;
    margin: 0;
  }
  * p {
    margin: 0;
  }
`;

export default ArticleCard;
