import React from "react";
import { Trans } from "@lingui/macro";
import { useLocalization } from "gatsby-theme-i18n";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import moment from "moment";
import { navigate } from "gatsby";
import styled from "@emotion/styled";
import NewLayout from "../components/NewLayout";
import blog from "../cms/blog/blog";
import Container from "../components/Container";
import header_image from "../images/headers/blog.png";
import ArticleCard from "../components/ArticleCard";
import ArrowButton from "../components/ArrowButton";
import Hero from "../components/Hero";
import ModalSuscribe from "../components/ModalSuscribe";
import Checkbox from "../components/Checkbox";

function Blog() {
  const { locale } = useLocalization();
  const articles = Object.values(blog[locale]);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const [currentArticles, setCurrentArticles] = React.useState(articles.slice(0, 10));
  const [showModal, setShowModal] = React.useState(false);
  const years = _.uniq(articles.map(({ attributes: { publish_date } }) => moment(publish_date).year()));
  const categories = _.uniq(_.flatten(articles.map(({ attributes: { article_categories: { data } } }) => data.map(({ attributes: { name } }) => name))));
  const tags = _.uniq(_.flatten(articles.map(({ attributes: { article_tags: { data } } }) => data.map(({ attributes: { name } }) => name))));

  React.useEffect(() => {
    const filteredArticles = articles.filter((item) => {
      const { attributes } = item;
      const date = moment(attributes.publish_date);
      if (
        filters.category
        && !attributes.article_categories.data
          .map(({ attributes }) => attributes.name)
          .includes(filters.category)
      ) return null;
      if (
        filters.tag
        && !attributes.article_tags.data
          .map(({ attributes }) => attributes.name)
          .includes(filters.tag)
      ) return null;
      if (filters.year && filters.year !== date.year()) return null;
      return item;
    });
    setCurrentArticles(_.isEmpty(filters) ? currentArticles : filteredArticles);
  }, [filters]);

  return (
    <>
      <NewLayout apps="media">
        <Hero
          title={<Trans>News</Trans>}
          description={(
            <Trans>
              Here you will find the latest press releases from Allfunds. For
              any media inquiries, please reach out to press@allfunds.com.
            </Trans>
          )}
          backgroundImage={header_image}
          kind={<Trans>Media</Trans>}
        />
        <Container>
          <Box sx={{ py: { xs: 4, sm: 8 } }}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  freeSolo
                  padding={0}
                  options={articles.map(({ attributes }) => ({ id: attributes.slug, label: attributes.title }))}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, item) => navigate(`/${locale}/blog/${item.id}`)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        ".MuiInputBase-root": {
                          padding: 0,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography ml={1}>
                              <Trans>Search news</Trans>
                              :
                            </Typography>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "100%",
                              background: (theme) => theme.palette.secondary.main,
                              padding: "1rem",
                              borderRadius: "5px",
                            }}
                          >
                            <FontAwesomeIcon color="white" icon={faSearch} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm
                sx={{
                  justifyContent: { sm: "end" },
                  display: { sm: "flex" },
                }}
              >
                <ArrowButton
                  path="#"
                  title={<Trans>Subscribe</Trans>}
                  onClick={() => setShowModal(true)}
                  isDark
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: { xs: 6, sm: 3 } }}>
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                  background: (theme) => theme.palette.primary.main,
                }}
              >
                <Box
                  padding={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography color="white" variant="h4">
                    <Trans>Browse Archive</Trans>
                  </Typography>
                  <Button onClick={() => setOpenSearch(!openSearch)}>
                    <FontAwesomeIcon
                      color="white"
                      size="2x"
                      icon={openSearch ? faAngleUp : faAngleDown}
                    />
                  </Button>
                </Box>
                {openSearch && (
                  <StyledGrid container p={2} sx={{ background: "white" }}>
                    <Grid item xs={12} md={4}>
                      <Typography mb={1} variant="h5">
                        <Trans>Category</Trans>
                      </Typography>
                      <Stack spacing={1}>
                        {categories.map((name) => (
                          <Checkbox
                            type="radio"
                            checked={name === filters.category}
                            onChange={() => setFilters({ ...filters, category: filters.category === name ? undefined : name })}
                          >
                            <Typography>{name}</Typography>
                          </Checkbox>
                        ))}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4} spacing={2}>
                      <Typography mb={1} variant="h5">
                        <Trans>Year</Trans>
                      </Typography>
                      <Autocomplete
                        id="grouped-demo"
                        options={years}
                        onChange={(e, value) => setFilters({ ...filters, year: value })}
                        sx={{ width: { xs: "100%", sm: 300 } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<Typography variant="small"><Trans>Select Year</Trans></Typography>}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography mb={1} variant="h5">
                        <Trans>Browse by tag</Trans>
                      </Typography>
                      <Stack spacing={1}>
                        {tags.map((name) => (
                          <Checkbox
                            type="radio"
                            checked={name === filters.tag}
                            onChange={() => setFilters({ ...filters, tag: filters.tag === name ? undefined : name })}
                          >
                            <Typography>{name}</Typography>
                          </Checkbox>
                        ))}
                      </Stack>
                    </Grid>
                  </StyledGrid>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
        <Container pb={10}>
          <Box mb={4}>
            <Grid container spacing={2}>
              {currentArticles.slice(0, 2).map(({ attributes }) => (
                <Grid item xs={12} sm={6}>
                  <ArticleCard attributes={attributes} isBig />
                </Grid>
              ))}
              {currentArticles.slice(2).map(({ attributes }) => (
                <Grid item xs={12} sm={6} md={3}>
                  <ArticleCard attributes={attributes} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {((!_.isEmpty(filters) && currentArticles.length > 10)
            || (articles.length > 10 && _.isEmpty(filters))) && (
            <Grid container spacing={2} textAlign="center" alignItems="center">
              <Grid item xs sm={5}>
                <hr />
              </Grid>
              <Grid item xs={6} sm>
                <Button variant="contained" onClick={() => setCurrentArticles(articles.length === currentArticles.length ? articles.slice(0, 10) : articles)}>
                  <Typography color="white">{articles.length === currentArticles.length ? <Trans>Show less</Trans> : <Trans>Load more</Trans>}</Typography>
                </Button>
              </Grid>
              <Grid item xs sm={5}>
                <hr />
              </Grid>
            </Grid>
          )}
        </Container>
      </NewLayout>
      {showModal && <ModalSuscribe onClose={() => setShowModal(false)} />}
    </>
  );
}

const StyledGrid = styled(Grid)(
  ({ theme }) => `
  > div {
    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(1)} 0;
    }
    ${theme.breakpoints.up("sm")} {
      padding: 0 ${theme.spacing(2)};
    }
  }
`,
);

export default Blog;
